<template>
  <div>
    <div class="wrapper">
      <h3>{{ url }}</h3>
      <div class="box-wrapper">
        <h5>Unsubscribed</h5>
        <p>We're sorry to see you go! Enter your email to unsubscribe from this list.</p>
        <label for="email">Email address</label>
        <input id="email" v-model="email" placeholder="Enter your email address" />
        <span>We'll never share your email with anyone else.</span>
        <v-btn depressed color="#7b7f83" @click="onSubmit">Unsubscribe</v-btn>

        <v-alert v-model="alert" :type="type" outlined>
          {{ message }}
        </v-alert>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import route from 'vue-router';

export default {
  name: 'Unsubscribe',
  data() {
    return {
      url: this.$route.query.domain,
      email: this.$route.query.email,
      alert: false,
      message: 'Fail',
      type: 'success',
    };
  },
  methods: {
    async onSubmit() {
      const emailFormat = /\S+@\S+\.\S+/;
      if (!this.email) {
        this.alert = true;
        this.type = 'error';
        this.message = 'Email is required!';
        return;
      }
      if (!emailFormat.test(this.email)) {
        this.alert = true;
        this.type = 'error';
        this.message = 'Invalid email address!';
        return;
      }

      try {
        const baseUrl = process.env.VUE_APP_UNSUBSCRIBE_URL;
        await axios(`${baseUrl}/api/users/unsubscribe?email=${this.email}`);
        this.alert = true;
        this.type = 'success';
        this.message = 'Unsubscribe successfully!';
      } catch (error) {
        this.alert = true;
        this.type = 'error';
        this.message = 'Fail';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.box-wrapper {
  //background: #55586c;
  width: 50%;
  margin: 0px auto;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  font-size: 1rem;

  h5 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
  }

  p {
    margin: 1rem 0px;
  }

  label {
    display: block;
    margin-bottom: 4px;
  }

  input {
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    width: 400px;
  }

  span {
    display: block;
    font-size: 0.75rem;
    opacity: 0.7;
    margin-top: 4px;
  }
}

.v-btn {
  color: white;
  margin-top: 25px;
  margin-bottom: 20px;
}

.wrapper {
  padding-top: 50px;
  margin-bottom: 8px;

  h3 {
    width: 50%;
    margin: 0px auto;
  }
}
</style>
